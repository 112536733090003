import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
const { REACT_APP_API_PATH } = process.env;

export const getRights = createAsyncThunk(
    "rights/getRights",
    async (_, thunkAPI) => {
        try {
            const response = await axiosInstance.get(`${REACT_APP_API_PATH}/derechos?periodo=2023`)
            const data = response.data
            return {
                rights: data.data,
                evaluation_periodicity: data.evaluation_periodicity
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);