import { IIndicator } from '../../interfaces/Indicator'
import ResponsiveContainer from '../../layouts/ResponsiveContainer'
import Indicator from '../../views/IndicatorDisplay/components/Indicator/Indicator'

export const IndicatorMapper = ({ indicators, totalItems }: { indicators: IIndicator[], totalItems: number }) => {
    return (
        <div className='flex justify-center items-center flex-col'>
            {/* Number of indicators */}
            <ResponsiveContainer>
                <p className='text-primary-10'>Indicadores encontrados: <span>{totalItems}</span></p>
            </ResponsiveContainer>
            <div className='flex flex-col items-center'>
                {
                    indicators && indicators.length > 0 && indicators.map(({
                        breadcrumbs, descripcion, titulo, state, notas, fuente, graphics, id, variable_indicador, data, documento_informativo, investigaciones, periodo
                    }) => (
                        <Indicator
                            breadcrumbs={breadcrumbs}
                            descripcion={descripcion}
                            titulo={titulo}
                            state={state}
                            periodo={periodo}
                            notas={notas}
                            fuente={fuente}
                            graphics={graphics}
                            key={id}
                            variable_indicador={variable_indicador}
                            data={data}
                            documento_informativo={documento_informativo}
                            investigaciones={investigaciones}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default IndicatorMapper