import React from 'react'

interface Data {
    fill?: string,
    name: string
}

interface ReferencesMapper {
    data: Data[]
}

const ReferencesMapper = ({ data }: ReferencesMapper) => {
    return (
        <div className='flex items-center gap-5 justify-center'>
            {
                data?.map((element, index) => (
                    <div className='flex items-center gap-2' key={index}>
                        <span style={{ backgroundColor: element.fill, width: '10px', height: '10px' }}></span>
                        <span style={{ color: element.fill}}>{element.name}</span>
                    </div>
                ))
            }
        </div>
    )
}

export default ReferencesMapper