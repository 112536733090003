import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dimensions, ExtendedRight, IOptions, searchOfIndicators, SubDimensions, } from '../../../../interfaces/Rights';
import { getExtendedRight, getSearchOfIndicators } from './filterAndSearchThunk';

interface IndicatorDisplayFilter {
    loading: "idle" | "pending" | "succeeded" | "failed";
    // --> START DATA FOR SUPER FILTER <--
    selectedRight: ExtendedRight | null;
    dimensionsOfTypeIndicator: Dimensions[] | null,
    subdimensionsOfDimension: SubDimensions[] | null
    indicatorTypesOptions: IOptions[];
    dimensionOptions: IOptions[] | null;
    subDimensionOptions: IOptions[] | null;
    indicatorsOptions: IOptions[] | null;
    // --> END DATA FOR SUPER FILTER <--
    // --> START DATA FOR SEARCH <--
    searchOfIndicators: searchOfIndicators[] | null | undefined;
    loadingSearch: boolean;
    errorSearch: string | undefined | null;
    // --> START DATA FOR SEARCH <--
}

const initialState: IndicatorDisplayFilter = {
    loading: 'idle',
    indicatorTypesOptions: [{ value: 'Resultados', id: 1 }, { value: 'Proceso', id: 2 }],
    selectedRight: null,
    dimensionsOfTypeIndicator: null,
    subdimensionsOfDimension: null,
    dimensionOptions: null,
    subDimensionOptions: null,
    indicatorsOptions: null,
    searchOfIndicators: null,
    loadingSearch: false,
    errorSearch: ''
}

//REUSABLE FUNCTIONS
const findOptionByTitle = <T extends { titulo: string }>(items: T[], title: string): T | undefined => {
    return items.find(item => item.titulo.toLowerCase() === title.toLowerCase().trim());
};

const createOptions = (items: { titulo: string, id: number }[]): IOptions[] => {
    return items.map(({ titulo, id }) => ({ value: titulo, id }));
};

export const indicatorDisplayFilterSlice = createSlice({
    name: 'indicator_display_filter',
    initialState,
    reducers: {
        setDimensionOptions: (state, action: PayloadAction<{ typeOfIndicator: string }>) => {
            if (!state.selectedRight || !state.selectedRight.tipos_indicadores.length) {
                console.error('Error: selectedRight is not defined or has no tipos_indicadores');
                return;
            }

            if (!action.payload.typeOfIndicator) {
                console.error('Error: selectedTypeOfIndicator is undefined or has an invalid value');
                return;
            }

            const copySelectedRight: ExtendedRight = JSON.parse(JSON.stringify(state.selectedRight));
            if (!copySelectedRight.tipos_indicadores.length) {
                return
            };

            const findTypeOfIndicator = findOptionByTitle(copySelectedRight.tipos_indicadores, action.payload.typeOfIndicator);
            if (!findTypeOfIndicator) return;

            //load dimension options and load dimension  of type of indicator state
            state.dimensionsOfTypeIndicator = findTypeOfIndicator.dimensiones
            state.dimensionOptions = createOptions(findTypeOfIndicator.dimensiones);

        },
        setSubDimensionOptions: (state, action: PayloadAction<{ dimension: string }>) => {
            if (!state.selectedRight || !state.dimensionsOfTypeIndicator) {
                return
            }

            if (!action.payload.dimension) {
                return
            }

            const dimensions: Dimensions[] = JSON.parse(JSON.stringify(state.dimensionsOfTypeIndicator));
            if (!dimensions.length) {
                return
            }

            const findDimention = findOptionByTitle(dimensions, action.payload.dimension)
            if (!findDimention) return

            state.subDimensionOptions = createOptions(findDimention.subdimensiones);
            state.subdimensionsOfDimension = findDimention.subdimensiones
        },
        setIndicatorsOptions: (state, action: PayloadAction<{ subdimension: string }>) => {
            if (!state.selectedRight || !state.subdimensionsOfDimension) {
                return
            }

            if (!action.payload.subdimension) {
                return
            }

            const subdimensions: SubDimensions[] = JSON.parse(JSON.stringify(state.subdimensionsOfDimension));
            if (!subdimensions.length) return;

            const findSubDimension = findOptionByTitle(subdimensions, action.payload.subdimension)
            if (!findSubDimension) return;

            state.indicatorsOptions = createOptions(findSubDimension.indicadores);
        },
        cleanOptions: (state, action: PayloadAction<'dimension' | 'subdimension' | 'indicators'>) => {
            switch (action.payload) {
                case 'dimension':
                    state.dimensionOptions = null;
                    state.subDimensionOptions = null;
                    state.indicatorsOptions = null;
                    break
                case 'subdimension':
                    state.subDimensionOptions = null;
                    state.indicatorsOptions = null;
                    break
                case 'indicators':
                    state.indicatorsOptions = null;
                    break
            }
        },
        cleanSearch: (state) => {
            state.errorSearch = null;
            state.searchOfIndicators = null
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getExtendedRight.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getExtendedRight.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.selectedRight = action.payload.extendedRight;
        });
        builder.addCase(getExtendedRight.rejected, (state) => {
            state.loading = "failed";
        });
        builder.addCase(getSearchOfIndicators.pending, (state) => {
            state.loadingSearch = true;
        });
        builder.addCase(getSearchOfIndicators.fulfilled, (state, action) => {
            state.loadingSearch = false;
            state.searchOfIndicators = action.payload.search?.map((element, index) => (
                {
                    titulo: element.titulo,
                    subdimension: element.breadcrumbs.split('>').at(-1) || '',
                    id: element.id,
                    breadcrumbs: element.breadcrumbs
                }
            ));
            state.errorSearch = action.payload.error;
        });
        builder.addCase(getSearchOfIndicators.rejected, (state, action) => {
            state.errorSearch = action.error.message
            state.loadingSearch = false;
        });
    }
});

export const { setDimensionOptions, setSubDimensionOptions, setIndicatorsOptions, cleanOptions, cleanSearch } = indicatorDisplayFilterSlice.actions;
export default indicatorDisplayFilterSlice.reducer;