import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Loading from '../../components/Loading/Loading';
import { getDetailAndRelatedMethodologies } from '../../redux/slices/methodologies/methodologyThunk';
import { getDetailAndRelatedresearchsByDerechoId } from '../../redux/slices/researchs/researchsThunk';
import DetailContent from '../../views/Details/Index'

const Detail = () => {
    const { id } = useParams();

    const dispatch = useAppDispatch();
    let methodology = useAppSelector(state => state.methodologies.methodologiesDetail);
    const loading = useAppSelector(state => state.methodologies.loading);
    const relatedResearchs = useAppSelector((state) => state.researchs?.related_researchsByDerecho)

    useEffect(() => {

        if (id) {
            if (id) {
                dispatch(getDetailAndRelatedMethodologies(id));
                dispatch(getDetailAndRelatedresearchsByDerechoId(id));
            }
        }

    }, [id, dispatch]);

    if (loading === 'pending' || !methodology) return <Loading />;

    return (
        <DetailContent
            content={methodology}
            relatedContent={relatedResearchs ?? []}
            titleRelatedContent='Investigaciones y acciones relacionados'
            subPath='investigaciones-y-acciones'
        />
    );
};

export default Detail;