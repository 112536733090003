import { BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts'
import { BarCharts, GraphicsDomain } from '../../interfaces/Indicator'
import useDataKey from '../../hooks/useDataKey';
import { forwardRef, useEffect, useState } from 'react';
import { getDomain } from '../../utils/utils';
import CustomToolTip from './CustomToolTip';

const CompositeBarsCharts = forwardRef<HTMLDivElement, { data: BarCharts[], variable_analisis: string[], range?: GraphicsDomain | null }>(({ data, variable_analisis, range }, ref) => {
    const { dataKey } = useDataKey(data);
    const [domain, setDomain] = useState<[number, number] | [string, string] | [number, string]>(['auto', 'auto']);

    useEffect(() => {
        if (variable_analisis.length) {
            const searchPredicate = (key: string) => key.toLowerCase().includes(variable_analisis[0]);

            if (range !== null && range!.minimo !== null && range!.maximo !== null ){
                setDomain([range!.minimo, range!.maximo]);
            }
            else {
                const [min, max] = getDomain(data, searchPredicate);
                setDomain([min, max]);
            }
        }
    }, [data, variable_analisis]);

    return (
        <ResponsiveContainer width={'100%'} height={406} ref={ref}>
            <BarChart data={data} margin={{ top: 52, right: 32, left: 32, bottom: 52 }}  >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                    domain={domain}
                    ticks={range?.ticks ?? undefined} 
                    tickCount={range?.intervalo ?? undefined}
                    stroke='#555C62'
                />
                <Tooltip content={<CustomToolTip />} />
                <Legend />
                {dataKey?.map((key, index) => (
                    <Bar name={key.key.split('-').map(entity => entity.split(':')[1]).join('/') ?? "-"} dataKey={key.key} fill={key.color} key={index} />
                ))}
            </BarChart>
        </ResponsiveContainer>
    )
});

export default CompositeBarsCharts