import { createSlice } from '@reduxjs/toolkit';
import { IIndicator } from '../../../../interfaces/Indicator';
import { getIndicators } from './indicatorThunk';
import { Pagination } from '../../../../interfaces/Pagination';

interface IndicatorDisplay {
    loading: "idle" | "pending" | "succeeded" | "failed";
    indicators: IIndicator[] | null;
    errors_indicators: boolean | undefined;
    pagination: Pagination
}

const initialState: IndicatorDisplay = {
    loading: 'idle',
    indicators: null,
    errors_indicators: false,
    pagination: { totalItems: 0, currentPage: 1, pageSize: 5, totalPages: 0 }
}

export const indicatorSlice = createSlice({
    name: 'indicator_display',
    initialState,
    reducers: {
        clearIndicators: (state) => {
            state.indicators = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getIndicators.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getIndicators.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.indicators = action.payload.indicator;
            state.pagination = action.payload.pagination;
            state.errors_indicators = action.payload.error;
        });
        builder.addCase(getIndicators.rejected, (state) => {
            state.loading = "failed";
            state.errors_indicators = true
        });
    }
})

export const { clearIndicators } = indicatorSlice.actions;
export default indicatorSlice.reducer;
