import Period from '../../../components/Inputs/Date/Period'
import CustomSelects, { ICustomSelects } from '../../../components/Inputs/Selects/CustomSelect';

interface ICategoryProps extends ICustomSelects {
    numberOfArticles: number | string;
    disabledCategories: boolean;
}

interface IDateProps {
    onHandleChangePeriodFrom: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onHandleChangePeriodTo: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabledPeriodFrom: boolean;
    disabledPeriodTo: boolean;
    minPeriodDateFrom: string;
    maxPeriodDateFrom: string;
    minPeriodDateTo: string;
    maxPeriodDateTo: string;
    valueDateFrom: string;
    valueDateTo: string;
}

interface IFilters {
    dateProps: IDateProps;
    categoryProps: ICategoryProps;
}

const Filters = ({ dateProps, categoryProps }: IFilters) => {
    const {
        onHandleChangePeriodFrom,
        onHandleChangePeriodTo,
        disabledPeriodFrom,
        disabledPeriodTo,
        minPeriodDateFrom,
        maxPeriodDateFrom,
        minPeriodDateTo,
        maxPeriodDateTo,
        valueDateFrom,
        valueDateTo,
    } = dateProps;

    const {
        options,
        numberOfArticles,
        onSelect,
        selectedItem,
        defaultSelectedItem,
        label,
        disabledCategories
    } = categoryProps;

    return (
        <div className='mt-16 flex flex-col justify-between'>
            <div className='flex flex-col gap-2 md:gap-0 md:flex-row justify-between items:start md:items-end w-full'>
                <span className='text-blarge'>Total de artículos: <span className='font-medium'>{numberOfArticles}</span>
                </span>
                <div className='flex flex-col sm:flex-row justify-between sm:justify-start sm:items-end gap-2 sm:gap-4 pt-3 sm:py-0'>
                    <CustomSelects
                        options={options}
                        onSelect={onSelect}
                        selectedItem={selectedItem}
                        defaultSelectedItem={defaultSelectedItem}
                        label={label}
                        disabled={disabledCategories}
                        className='min-w-[150px]'
                    />
                    <Period
                        labelPeriod='Desde'
                        onHandleChangePeriod={onHandleChangePeriodFrom}
                        disabledPeriod={disabledPeriodFrom}
                        minPeriodDate={minPeriodDateFrom}
                        maxPeriodDate={maxPeriodDateFrom}
                        value={valueDateFrom}
                    />
                    <Period
                        labelPeriod='Hasta'
                        onHandleChangePeriod={onHandleChangePeriodTo}
                        disabledPeriod={disabledPeriodTo}
                        minPeriodDate={minPeriodDateTo}
                        maxPeriodDate={maxPeriodDateTo}
                        value={valueDateTo}
                    />
                </div>
            </div>
        </div>
    )
}

export default Filters