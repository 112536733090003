import { useEffect, useState } from "react"
import TitleEvaluations from "../../../components/Evaluations/TitleEvaluations"
import { DimensionEvaluation, Subdimensiones } from "../../../interfaces/Evaluations"
import ResponsiveContainer from "../../../layouts/ResponsiveContainer"
import Tabs from "../../../components/Tabs/Tabs"
import EvaluationSubdimensionResAndProc from "./EvaluationSubdimensionResAndProc"
import { INDICATOR_TYPE_STRUCTURAL } from "../../../utils/const"
import { useAppDispatch } from "../../../redux/hooks"
import { evaluationSubDimension } from "../../../redux/slices/evaluator/evaluatorSlice"
import EvaluationSubdimensionStructural from "./EvaluationSubdimensionStructural"

interface IEvaluationDimension {
    evaluation_dimension: DimensionEvaluation[],
    evaluation_subDimension_RES_PROC: Subdimensiones[],
    selected_type_indicator: string | null
}

const EvaluationDimension = ({ evaluation_dimension, evaluation_subDimension_RES_PROC, selected_type_indicator }: IEvaluationDimension) => {
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        // Seleccionar la primera dimensión al cambiar el tipo de indicador
        if (evaluation_dimension.length > 0) {
            const firstTabId = evaluation_dimension[0].id;
            setSelectedId(firstTabId);
            dispatch(evaluationSubDimension({ dimensionId: firstTabId }));
        }
    }, [selected_type_indicator, evaluation_dimension, dispatch]);


    const handleTabClick = (id: number) => {
        setSelectedId(id);
        dispatch(evaluationSubDimension({ dimensionId: id }))
    };

    return (
        <div className='flex justify-center items-center flex-col'>
            {selected_type_indicator && selected_type_indicator.length !== null ? (
                <ResponsiveContainer>
                    <TitleEvaluations label='Dimensiones' textStyle="text-hsmall mt-10" />
                    <Tabs
                        tabs={evaluation_dimension}
                        selectedIndex={selectedId}
                        onTabClick={handleTabClick}
                    />
                    {selected_type_indicator === INDICATOR_TYPE_STRUCTURAL
                        ? <EvaluationSubdimensionStructural
                            evaluation_subDimension={evaluation_subDimension_RES_PROC}
                        />
                        : <EvaluationSubdimensionResAndProc
                            selected_type_indicator={selected_type_indicator}
                            evaluation_subDimension={evaluation_subDimension_RES_PROC}
                        />
                    }
                </ResponsiveContainer>
            ) : null}
        </div>
    );
}

export default EvaluationDimension