import { createSlice } from '@reduxjs/toolkit'
import { getVideo } from './monitorThunk';
import { Video } from '../../../interfaces/MediaAndContent';

interface MonitorState {
    video: Video | null
    loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: MonitorState = {
    loading: 'idle',
    video: null
}

export const monitorSlice = createSlice({
    name: 'monitor',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVideo.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getVideo.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.video = action.payload.video
        });
        builder.addCase(getVideo.rejected, (state) => {
            state.loading = "failed";
        });

    },
})

export const { } = monitorSlice.actions;

export default monitorSlice.reducer;