import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../styles.css'
import { titleCase } from '../../utils/utils';

interface IBreadcrumbsNamedProps {
    baseName: string;
}

const BreadcrumbsNamed = ({ baseName }: IBreadcrumbsNamedProps) => {
    const location = useLocation();
    let currentLink = "";

    const decodedPathname = location.pathname;
    const expresionRegular = /id(\d+)/;

    const isNumeric = (value: string): boolean => {
        return !isNaN(Number(value));
    };

    const crumbs = decodedPathname.split('/')
        .filter((crumb) => crumb !== '')
        .map((crumb, index) => {
            currentLink += `/${crumb}`;

            const isCurrentLink = currentLink === location.pathname;

            // Conditional rendering for "Qué es?" when crumb is "que-es"
            let crumbText = titleCase(decodeURIComponent(crumb.replaceAll('-', ' ').replace(expresionRegular, '')));
            if (crumb === 'que-es') {
                crumbText = '¿Qué es?';
            }

            if (crumb === 'metodologia') {
                crumbText = 'Metodología';
            }

            if (isNumeric(crumb) && baseName != undefined) {
                crumbText = baseName;
            }

            return (
                <p key={crumb} className='flex gap-1'>
                    {index > 0 && " / "}
                    <Link
                        to={currentLink}
                        className={isCurrentLink ? "text-primary-40 truncate max-w-64 sm:max-w-80 lg:max-w-full block " : ""}
                    >
                        {crumbText}
                    </Link>
                </p>
            );
        });

    return (
        <div className='flex flex-wrap gap-1 text-tmedium text-[#70787D] '>
            Defensoría / {crumbs}
        </div>
    );
};

export default BreadcrumbsNamed;