import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../api/axiosInstance";
import { IIndicator } from "../../../../interfaces/Indicator";
import { ErrorResponse } from "react-router-dom";
import { Pagination } from "../../../../interfaces/Pagination";
const { REACT_APP_API_PATH } = process.env;

interface GetIndicatorsQuery {
    right: string;
    search?: string;
    indicadorId?: string;
    typeIndicator?: string;
    subdimensionId?: string;
    page: number
    limit: number;
}

interface GetIndicatorResponse {
    indicator: IIndicator[] | null
    error?: boolean
    pagination: Pagination
}

export const getIndicators = createAsyncThunk<GetIndicatorResponse, GetIndicatorsQuery, { rejectValue: ErrorResponse }>(
    "display/indicators",
    async ({ right, search, indicadorId, subdimensionId, page, limit, typeIndicator }: GetIndicatorsQuery) => {
        try {
            let url = `${REACT_APP_API_PATH}/indicadores?titulo=${right}`;

            if (search) url += `&search=${search}`
            if (indicadorId) url += `&indicadorId=${indicadorId}`
            if (typeIndicator) url += `&tipoIndicador=${typeIndicator}`
            if (subdimensionId) url += `&tituloSubdimension=${subdimensionId}`
            if (page) url += `&page=${page}`
            if (limit) url += `&limit=${limit}`


            const response = await axiosInstance.get(url)
            const data = response.data
            return {
                indicator: data.data.data,
                pagination: {
                    currentPage: Number(data.data.page),
                    pageSize: Number(data.data.limit),
                    totalItems: Number(data.data.totalItems),
                    totalPages: Number(data.data.totalPages),
                },
                error: false
            }
        } catch (error: any) {
            return {
                indicator: null,
                pagination: { totalItems: 0, currentPage: 0, totalPages: 0, pageSize: 0 },
                error: true
            };
        }
    }
);

