import { createSlice } from '@reduxjs/toolkit'
import { getRights } from './rightsThunk';
import { IRights, IOptions, EvaluatedRights } from '../../../interfaces/Rights';

interface RightsState {
    rights: EvaluatedRights[] | null;
    loading: "idle" | "pending" | "succeeded" | "failed";
    evaluation_periodicity: string;
    rights_options: IOptions[] | null;
}

const initialState: RightsState = {
    loading: 'idle',
    rights: null,
    evaluation_periodicity: '',
    rights_options: null
}

export const rightsSlice = createSlice({
    name: 'rights',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getRights.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getRights.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.rights = action.payload.rights;
            state.evaluation_periodicity = 'Período 2023';
            state.rights_options = action.payload.rights.map(({ titulo, id }: IRights) => (
                {
                    value: titulo,
                    id: id
                }
            ))
        });
        builder.addCase(getRights.rejected, (state) => {
            state.loading = "failed";
        });

    },
})

export const { } = rightsSlice.actions;

export default rightsSlice.reducer;