import * as htmlToImage from "html-to-image";
import { calculateCanvasHeight, wrapText } from "../../../hooks/useDownloadChart";

export const handleDownloadMap = (
  nodeRef: React.RefObject<HTMLDivElement>,
  name: string,
  filtersSelected: { [key: string]: string },
  breadcrumbs: string,
  name_indicator: string,
  note: string,
  source: string,
  format: "png" | "jpg"
) => {
  const node = nodeRef.current;
  const margin = 20;

  if (node) {
    node.style.position = "relative"; // Asegurar que no hay problemas con html-to-image
    const scale = 1;

    htmlToImage
      .toCanvas(node, {
        width: (node.offsetWidth) * scale,
        height: node.offsetHeight * scale,
        style: {
          transform: `scale(${scale})`,
          transformOrigin: "top center",
          width: `${node.offsetWidth}px`,
          height: `${node.offsetHeight}px`,
        },
      })
      .then((canvas) => {

        const newCanvas = document.createElement("canvas");
        const context = newCanvas.getContext("2d");
        const lineHeight = 20;

        newCanvas.width = canvas.width;
        newCanvas.height = calculateCanvasHeight(
          breadcrumbs,
          filtersSelected,
          [],
          "",
          canvas.height,
          context!,
          margin,
          note,
          0,
          source
        ) + margin * 2;

        if (context) {

          if (format === 'jpg') {
            context.fillStyle = "#FFFFFF";
            context.fillRect(0, 0, canvas.width, newCanvas.height);
          }

          let contentHeight = margin + 20;

          // Dibuja el breadcrumb en la parte superior del canvas
          context.font = "14px Roboto";
          context.fillStyle = "#000";
          context.textAlign = "left";

          // Ajusta contentHeight con la altura real del breadcrumb
          const breadcrumbHeight = wrapText(
            context,
            `${breadcrumbs.replaceAll(">", " / ")} / ${name_indicator}`,
            margin,
            contentHeight,
            canvas.width - margin * 2,
            lineHeight
          );

          contentHeight += breadcrumbHeight + 10; // Agregar espacio adicional antes del título

          // titulo
          context.font = "bold 20px Roboto";
          context.fillStyle = "#000";
          context.textAlign = "left";

          const titleHeight = wrapText(
            context,
            name,
            margin,
            contentHeight,
            canvas.width - margin * 2,
            lineHeight
          );
          contentHeight += titleHeight + 10; // Añade espacio debajo del título

          // Dibuja los filtros seleccionados debajo del título
          if (filtersSelected && Object.keys(filtersSelected).length > 0) {
            const filtersTextLines = Object.entries(filtersSelected).map(
              ([key, value]) => `${key}: ${value}`
            );

            context.font = "14px Roboto";
            context.fillStyle = "#000";

            context.fillText("Filtros seleccionados:", margin, contentHeight);
            contentHeight += 20; // Espacio para el header de filtros

            filtersTextLines.forEach((line) => {
              context.fillStyle = "#666";
              context.fillText(line, margin, contentHeight);
              contentHeight += 20; // Espacio para cada línea de filtro
            });

            contentHeight += 10; // Espacio adicional después de los filtros
          }

          // Dibuja el mapa debajo de los filtros seleccionados
          context.drawImage(canvas, margin, contentHeight, canvas.width, canvas.height);
          contentHeight += canvas.height + 40; // Añade espacio después del gráfico

          // Dibuja la nota y el source debajo de la leyenda
          context.font = "14px Roboto";
          context.fillStyle = "#000";
          const maxWidth = canvas.width - margin * 2 - 40;

          if (note) {
            const noteHeight = wrapText(
              context,
              `Nota: ${note}`,
              margin,
              contentHeight,
              maxWidth,
              lineHeight
            );
            contentHeight += noteHeight + 10; // Ajustar la altura según el texto
          }

          if (source) {
            const sourceHeight = wrapText(
              context,
              `Fuente: ${source}`,
              margin,
              contentHeight,
              maxWidth,
              lineHeight
            );
            contentHeight += sourceHeight + 10; // Ajustar la altura según el texto
          }
        }

        const imgData = newCanvas.toDataURL(`image/${format}`);
        const link = document.createElement("a");
        link.href = imgData;
        link.download = `${name}.${format}`;
        link.click();
      })
      .catch((error) => {
        console.error("Error al generar la imagen del mapa:", error);
      });
  } else {
    console.error("No se encontró el nodo para capturar");
  }
};
