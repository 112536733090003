import Button from '../Buttons/Button';
import '../styles.css'
import { useNavigate } from 'react-router-dom';
import { MdArrowForward } from 'react-icons/md';

interface IDynamicBannerCard {
    title: string,
    colorTitle: string,
    text: string,
    colorText: string,
    icon: string,
    buttonLabel: string,
    buttonUrl: string,
    bgColor: string
    classNameButton: string,
    colorPolygon: string
}

const DynamicBannerCard = ({
    title,
    colorTitle,
    text,
    colorText,
    icon,
    buttonLabel = "Ver",
    buttonUrl = "#",
    bgColor = 'pink',
    classNameButton,
    colorPolygon
}: IDynamicBannerCard) => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(`${buttonUrl}`)
    }

    return (
        <>
            <div className='w-[20.5rem] sm:w-[33.5rem] md:w-[53.563rem] lg:w-[55rem] xl:w-[70.5rem] h-[220px] rounded-xl relative' style={{ backgroundColor: bgColor }}>
                <div className="dynamic-banner w-full rounded-xl"></div>
                <div className="w-full lg:max-xl:w-[55rem] h-[13.75rem] relative rounded-xl overflow-hidden" >
                    <div className='h-full flex flex-col justify-center items-start pl-8 gap-4'>
                        <div>
                            <h3 className={`text-tlarge ${colorTitle}`}>{title}</h3>
                            <h4 className={`${!title.length ? 'text-tsmall md:text-tmedium xl:text-tlarge' : 'text-bmedium'} ${colorText} sm:w-8/12 md:w-11/12 xl:w-full mt-1`}>{text}</h4>
                        </div>
                        <Button
                            label={buttonLabel}
                            icon={MdArrowForward}
                            as='button'
                            className={classNameButton}
                            onClick={handleOnClick}
                        />
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="305" height="280" viewBox="0 0 305 280" fill="none" className='absolute top-[-50px] right-[-100px] hidden sm:inline' >
                        <path d="M98.7695 40.9548C122.65 0 182.35 0 206.23 40.9548L296.595 197.136C320.476 238.409 290.625 280 242.865 280H62.1352C14.3747 280 -15.4755 238.409 8.40469 197.136L98.7695 40.9548Z" fill={colorPolygon} />
                    </svg>
                    <img src={icon} alt="icon-file" className='absolute right-[2rem] bottom-[3.5rem] z-10 hidden sm:inline' />
                </div>
            </div>

        </>
    )
};

export default DynamicBannerCard;