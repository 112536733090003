import { useEffect, useState } from 'react';
import { Evaluaciones } from '../../interfaces/Evaluations';
import '../styles.css';
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import LawCard from '../Cards/LawCard';
import Button from '../Buttons/Button';

const Timeline = ({ laws, period }: { laws: Evaluaciones, period: string }) => {
    const [selectedYear, setSelectedYear] = useState<string | null>(period);
    const [showAll, setShowAll] = useState(false);

    const sortedYears = Object.keys(laws).sort((a, b) => parseInt(b) - parseInt(a));

    useEffect(() => {
        setSelectedYear(period);
    }, [period]);

    const handleMoveTimeline = (direction: 'prev' | 'next') => {
        if (!selectedYear) return;

        const currentIndex = sortedYears.indexOf(selectedYear);
        if (direction === 'prev') {
            setSelectedYear(sortedYears[currentIndex - 1]);
        } else if (direction === 'next') {
            setSelectedYear(sortedYears[currentIndex + 1]);
        }
    };

    const toggleShowAll = () => {
        setShowAll(!showAll); // Alternar entre mostrar todos y mostrar solo los primeros tres
    };

    return (
        <>
            <div className='rounded-lg shadow-lg border border-system-light-60'>
                <div className='p-8'>
                    <div className="timeline">
                        <div className="middle-line"></div>
                        {laws && sortedYears.map((year) => (
                            <button
                                key={year}
                                className={`timeline-point box ${selectedYear === year ? 'active' : ''}`}
                                onClick={() => setSelectedYear(year)}
                            >
                                <span className={`${selectedYear === year ? 'text-tmedium text-primary-40' : 'text-bsmall text-[#001F29]'}`}>{year}</span>
                                <div className={`relative ${selectedYear === year ? 'w-[36px] h-[36px] border rounded-full border-[#51BCAD] bg-primary-100' : 'w-[22px] h-[22px] border rounded-full border-[#C0C8CC] bg-primary-100'}`}>
                                    <div className={`absolute ${selectedYear === year ? 'w-[22px] h-[22px] rounded-full bg-[#51BCAD] top-[6px] right-[6px]' : 'w-[12px] h-[12px] rounded-full bg-[#C0C8CC] top-[4px] right-[4px]'}`}></div>
                                </div>
                            </button>
                        ))}
                    </div>

                    <div className='flex justify-end my-8 gap-6'>
                        <button
                            onClick={() => handleMoveTimeline('prev')}
                            className={`p-2  rounded-xl  ${selectedYear === sortedYears[0] ? 'opacity-50 cursor-not-allowed bg-system-light-60 text-primary-10' : 'bg-[#BBE9FF] text-primary-10'}`}
                            disabled={selectedYear === sortedYears[0]} // Deshabilitar si es el primer año (2023)
                        >
                            <MdArrowBack size={24} />
                        </button>
                        <button
                            onClick={() => handleMoveTimeline('next')}
                            disabled={selectedYear === sortedYears[sortedYears.length - 1]} // Deshabilitar si es el último año (2018)
                            className={`p-2 rounded-xl ${selectedYear === sortedYears[sortedYears.length - 1] ? 'opacity-50 cursor-not-allowed bg-system-light-60 text-primary-10' : 'bg-[#BBE9FF] text-primary-10'}`}
                        >
                            <MdArrowForward size={24} />
                        </button>
                    </div>

                    <div className="mt-4">
                        {selectedYear && (
                            <div className='flex flex-col items-center gap-4'>
                                {laws[selectedYear]?.slice(0, showAll ? laws[selectedYear].length : 3).map((evalItem, index) => (
                                    <LawCard
                                        key={index}
                                        name={evalItem.nombre}
                                        text={evalItem.texto}
                                        fileName={evalItem.pdf}
                                    />
                                ))}
                                {laws[selectedYear]?.length > 3 && (
                                    <Button
                                        label={showAll ? 'Ver menos' : 'Ver más'}
                                        onClick={toggleShowAll}
                                        className="bg-primary-40 text-primary-100 mt-4"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Timeline;