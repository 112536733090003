import ResponsiveContainer from '../../../../layouts/ResponsiveContainer';
import ContainerGraphs from '../Graphics/ContainerGraphs';
import { IIndicator } from '../../../../interfaces/Indicator';
import HeaderIndicator from './HeaderIndicator';
import FooterIndicator from './FooterIndicator';
import CTABox from '../../../../components/Cards/CTABox';
import { MdArrowForward } from 'react-icons/md';

const Indicator = ({ breadcrumbs, titulo, state, periodo, descripcion, notas, fuente, graphics, variable_indicador, data, documento_informativo, investigaciones }: IIndicator) => {

    return (
        <ResponsiveContainer className='flex flex-col gap-6 border-b-2  border-[#DCE4E9] pb-8 mt-8 '>
            <HeaderIndicator
                breadcrumbs={breadcrumbs}
                titulo={titulo}
                state={state}
                periodo={periodo}
                descripcion={descripcion}
                notas={notas}
                documento_informativo={documento_informativo}
                investigaciones={investigaciones}
            />
            <ContainerGraphs
                breadcrumbs={breadcrumbs}
                graphics={graphics}
                source={fuente}
                variables_indicator={variable_indicador}
                titulo={titulo}
                data={data}
            />
            {
                fuente && <FooterIndicator fuente={fuente} />
            }

           {
            investigaciones. length > 0 ?
            <CTABox
                title="Investigaciones y acciones relacionadas"
                linkPath="/monitor/investigaciones-y-acciones"
                buttonLabel="Conocer"
                buttonIcon={MdArrowForward}
                background="bg-[#9EF2E4]/30 "
            /> : null
           } 
        </ResponsiveContainer>
    )
}

export default Indicator