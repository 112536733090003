import Titles from '../../../components/Titles/Titles'
import ResponsiveContainer from '../../../layouts/ResponsiveContainer'
import EvaluationTypeIndicator from '../../../components/Cards/EvaluationTypeIndicator';
import { IndicatorTypeEvaluation } from '../../../interfaces/Evaluations';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { evaluationDimension, selectedTypeIndicator, clearDimensionEvaluations } from '../../../redux/slices/evaluator/evaluatorSlice';
import { useNavigate } from 'react-router-dom';

const EvaluationTypeOfIndicator = ({ evaluation_indicator_type }: { evaluation_indicator_type: IndicatorTypeEvaluation[] }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const selectedTypeId = useAppSelector((state) => state.evaluator.selected_type_indicator);

    const handleClick = (typeOfIndicatorId: number, typeOfIndicatorName: string) => {
        const params = new URLSearchParams(window.location.search);
        params.set('typeIndicator', typeOfIndicatorName)
        navigate({ search: params.toString() });

        dispatch(clearDimensionEvaluations());
        dispatch(selectedTypeIndicator({ typeOfIndicator: typeOfIndicatorName }));
        //Para obtener la evaluaciones de las dimensiones necesita saber cual es el tipo de indicador que las contiene
        dispatch(evaluationDimension({ typeOfIndicator: typeOfIndicatorId }));
    };

    return (
        <div className='flex justify-center items-center flex-col'>
            <ResponsiveContainer className='mt-12 flex flex-col items-center'>
                <Titles title='Tipos de indicadores' />
                <div className='flex w-full w-full justify-between mt-10 gap-4'>
                    {evaluation_indicator_type && evaluation_indicator_type.length > 0 && evaluation_indicator_type.map((element) => (
                        <EvaluationTypeIndicator
                            label={element.nombre}
                            status={element.trivalente_tipo_indicador}
                            size={12}
                            key={element.id}
                            isSelected={selectedTypeId === element.nombre}
                            handleClick={() => handleClick(element.id, element.nombre)}
                        />
                    ))}
                </div>
            </ResponsiveContainer>
        </div>
    )
}

export default EvaluationTypeOfIndicator