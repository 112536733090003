import { useEffect, useState, useMemo } from 'react';
import Dropdown, { CustomSelectsOption } from '../../../../components/Inputs/Selects/CustomSelect';
import { AllData, Variable_indicator } from '../../../../interfaces/Indicator';
import { INDICATOR_TYPE_RESULTS } from '../../../../utils/const';
import { filterByCriteria } from '../../../../utils/utils';

type Variables = {
  name: string;
  values: CustomSelectsOption[];
}

interface FilterOfGraphsProps {
  variables_indicador: Variable_indicator[];
  typeOfIndicator: string;
  data: AllData[]
  setDataChart: (data: any[]) => void;
  variable_analisis: string[];
  cleanFilters: boolean;
  setCleanFilters: React.Dispatch<React.SetStateAction<boolean>>
  setFiltersSelected: React.Dispatch<React.SetStateAction<{
    [key: string]: string;
  }>>;
  isPercentageChart: null | 1 | 0;
  handleCleanFilters: () => void
}

const FilterOfGraphs = ({
  variables_indicador,
  typeOfIndicator,
  data,
  setDataChart,
  variable_analisis,
  cleanFilters,
  setCleanFilters,
  setFiltersSelected,
  isPercentageChart,
  handleCleanFilters
}: FilterOfGraphsProps) => {
  const [variables, setVariables] = useState<Variables[]>([]);
  const [selectedVariables, setSelectedVariables] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (cleanFilters) {
      setSelectedVariables({});
      setCleanFilters(false);
    }
  }, [cleanFilters]);

  const selectedVariable = (label: string, value?: string) => {
    setSelectedVariables((prevSelected) => ({
      ...prevSelected,
      [label]: value || '',
    }));

    const filtro = { ...selectedVariables, [label]: value || '' };

    const filteredProducts = filterByCriteria(filtro, data, variable_analisis?.[0] || '');
    setDataChart(filteredProducts);
    setFiltersSelected(filtro);
  };

  const transformedVariables = useMemo(() => {
    if (variables_indicador && Array.isArray(variables_indicador)) {
      const variablesTransformed = variables_indicador
        .filter(variable => variable.values.some(value => value !== null))
        .map(variable => ({
          name: variable.titulo,
          values: [
            ...(isPercentageChart && variable_analisis?.includes(variable.titulo)
              ? []
              : [{ value: 'Todos', id: 0 }]
            ),
            ...variable.values
              .filter(value => value !== null)
              .map((value: string, index: number) => ({
                value: value,
                id: index + 1
              }))
          ]
        }));

      // Ordenamos de modo que variable_analisis (si existe) aparezca primero
      return variablesTransformed.sort((a, b) => {
        if (variable_analisis?.includes(a.name)) return -1;
        if (variable_analisis?.includes(b.name)) return 1;
        return 0;
      });
    }
    return [];
  }, [variables_indicador, isPercentageChart, variable_analisis]);

  useEffect(() => {
    setVariables(transformedVariables);
  }, [transformedVariables]);

  useEffect(() => {
    if (isPercentageChart && variable_analisis && variable_analisis.length > 0 && variables_indicador.length > 1) {
      const firstVariable = variable_analisis[0];
      const firstValue = variables.find(v => v.name === firstVariable)?.values[0]?.value || '';

      if (firstValue) {
        selectedVariable(firstVariable, firstValue);
      }
    }
  }, [isPercentageChart, variables, variable_analisis]);

  if (typeOfIndicator !== INDICATOR_TYPE_RESULTS) return null

  return (
    <div className='flex wrap gap-3 items-end'>
      {variables.length > 0 ? (
        variables.map(variable => (
          <Dropdown
            key={variable.name}
            options={variable.values}
            onSelect={selectedVariable}
            selectedItem={selectedVariables[variable.name] || ''}
            defaultSelectedItem={selectedVariables[variable.name] || 'Seleccione'}
            label={variable.name}
            className='min-w-[175px]'
          />
        ))
      ) : null}

      {variables.length > 0 && Object.keys(selectedVariables).length > 0 && (
        <button
          onClick={handleCleanFilters} // Acción del botón, como limpiar filtros
          className='text-llarge text-primary-40 h-[20px] min-w-[7rem] rounded hover:bg-blue-600 transition-colors'
        >
          Limpiar Filtros
        </button>
      )}
    </div>
  );
}

export default FilterOfGraphs;