import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Loading from '../../components/Loading/Loading';
import DetailContent from '../Details/Index';
import { getDetailAndRelatedresearchs } from '../../redux/slices/researchs/researchsThunk';
import { getValueID } from '../../utils/utils';

const Detail = () => {
    const { title } = useParams();
    const dispatch = useAppDispatch();
    let researchs = useAppSelector(state => state.researchs.researchsDetail);
    const loading = useAppSelector(state => state.researchs.loading);
    const relatedResearchs = useAppSelector((state) => state.researchs?.relatedresearchs)

    useEffect(() => {
        if (title) {
            const id = getValueID(title)
            if (id) {
                dispatch(getDetailAndRelatedresearchs(id));
            }
        }
    }, [title, dispatch]);

    if (loading === 'pending' || !researchs) return <Loading />;

    return (
        <DetailContent
            content={researchs}
            relatedContent={relatedResearchs ?? []}
            titleRelatedContent='Investigaciones y Acciones relacionadas'
            subPath='investigaciones-y-acciones'
        />
    );
};

export default Detail;