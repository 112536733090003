import React from 'react'

interface ChipState {
    state: number,
    periodo: string
}

const ChipState = ({ state, periodo }: ChipState) => {
    const statusClasses: Record<string, string> = {
        '-1': 'bg-indicator-red', //Red to 'Failed'
        '0': 'bg-indicator-yellow', // Yellow to 'Not changes'
        '1': 'bg-indicator-green', // Green to 'Better',
    };

    //Color for default
    const defaultClass = 'bg-indicator-null';

    const statusClass = state !== null ? statusClasses[state.toString()] : defaultClass;

    return (
        <div className='rounded-lg bg bg-primary-10 flex gap-3 items-center'>
            <div className='py-2 px-3 flex justify-between gap-3' >
                {periodo != null ?  <span className='text-primary-100 text-tsmall'> Estado ({periodo})</span> : <span className='text-primary-100 text-tsmall'> Estado </span>}
                <span className={`${statusClass} rounded-full w-[18px] h-[18px]`}></span>
            </div>
        </div>
    )
}

export default ChipState