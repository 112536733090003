import logoMini from '../assets/icons/logo-defensoria.png'
import { FiChevronsUp } from "react-icons/fi";
import SocialNetworks from './SocialNetworks';
import ResponsiveContainer from './ResponsiveContainer';

const Footer = () => {

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const VERSION = 'Version 4.1.3';

    return (
        <div className=' bg-[#71ADA4] mt-24 relative bottom-0'>
            <div className='flex flex-col justify-center items-center gap-6 text-primary-100 text-llarge pt-8'>
                <img src={logoMini} alt='logo-mini-defensoria' className='w-min h-min' />
                <SocialNetworks withFlag={false} gap={4} />
                <ResponsiveContainer className='flex flex-col gap-4 text-center mb-14'>
                    <div className='text-llarge'>
                        <p>Atención presencial:</p>
                        <p>Av. Belgrano 673. Lunes a Viernes de 10:00 hs. a 17:00 hs.</p>
                    </div>
                    <div className='text-llarge'>
                        <p>Tel: 0800-999-3722 / 11-7128-8301</p>
                    </div>
                    <p className='text-lmedium'>{VERSION}</p>
                </ResponsiveContainer>
                <button className='py-1 px-8 bg-primary-100 rounded-t-lg no-underline border-0 absolute bottom-0' onClick={goToTop}>
                    <FiChevronsUp color='#264653' size={24} />
                </button>
            </div>
        </div>
    )
}

export default Footer