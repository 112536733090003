import { AllData } from "../interfaces/Indicator";

function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
        var r = parseInt(result[1], 16);
        var g = parseInt(result[2], 16);
        var b = parseInt(result[3], 16);
        return r + "," + g + "," + b;
    }
    return undefined;
}

function titleCase(string: string) {
    let stringCapitalize = string[0].toLocaleUpperCase() + string.slice(1).toLowerCase()
    return stringCapitalize
}

function getImageFormat(filename: string): string | null {
    const regex = /(?:\.([^.]+))?$/;
    const match = regex.exec(filename);

    if (match && match[1]) {
        const format = match[1].toLowerCase();
        const validFormats = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg'];

        if (validFormats.includes(format)) {
            return format;
        } else {
            return 'Invalid format';
        }
    }

    return null;
}

const getValueID = (string: string | undefined) => {
    if (string !== undefined) {
        const expresionRegular = /id(\d+)/;
        const value_ID = string.match(expresionRegular);

        if (value_ID) {
            return value_ID[1]

        } else {
            return null
        }
    } else {
        return
    }
}

type Predicate = (key: string) => boolean;

const getDomain = (data: any[], searchPredicate: Predicate): [number, number] => {
    const findKey = (item: any) => Object.keys(item).find(searchPredicate);

    const values = data
        .map(item => {
            const key = findKey(item);
            return key ? parseFloat(item[key]) : NaN;
        })
        .filter(value => !isNaN(value));

    if (values.length === 0) {
        return [0, 1];
    }

    const min = Math.min(...values);
    const max = Math.max(...values);
    const padding = (max - min) * 0.1;  // 10% padding on top and bottom
    return [min - padding, max + padding];
};

/**
 * Función de filtro de indicadores
*/
const filterByCriteria = (
    criteria: { [key: string]: string },
    data: AllData[],
    pivot: string // Variable pivot para agrupar los datos
) => {

    const filteredData = data.filter(item => {
        return Object.keys(criteria).every(key => {
            if (criteria[key].toLowerCase() === 'todos') {
                return true;
            }

            if (criteria[key]) {
                return item.encabezado.includes(criteria[key]);
            }

            return true;
        });
    });

    const groupedData: { [key: string]: any } = {};

    filteredData.forEach(({ anio, encabezado, valor }) => {
        // Divide el encabezado en partes
        const parts = encabezado.split('-');
        const pivotIndex = parts.findIndex(part => part.startsWith(pivot));

        if (pivotIndex !== -1) {
            const pivotValue = parts[pivotIndex]; // El valor del pivot
            const simplifiedHeader = pivotValue.split('.')[1];

            if (!groupedData[anio]) {
                groupedData[anio] = { name: anio };
            }

            groupedData[anio][simplifiedHeader] = (groupedData[anio][simplifiedHeader] || 0) + parseFloat(valor);
        }
    });

    const transformedData = Object.values(groupedData);

    return transformedData;
};

const formatObjectToString = (obj: Record<string, string>): string => {
    return Object.entries(obj)
        .map(([key, value]) => `${key}-${value}`)
        .join('_'); // Usa el separador que prefieras, en este caso '_'
};

export { hexToRgb, titleCase, getImageFormat, getValueID, getDomain, filterByCriteria, formatObjectToString }