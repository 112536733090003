export const colors = [
    '#81B4DF',
    '#F49C6C',
    '#3AA492',
    '#E3C076',
    '#86C47C',
    '#E7DAA3',
    '#F49C6C',
    '#E76B5D',
    '#8F6CAE',
    '#BE5B82',
    '#1F4550'
]