import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Loading from '../../components/Loading/Loading';
import DetailContent from '../../views/Details/Index';
import { getDetailAndRelatedActivities } from '../../redux/slices/activities/activitiesThunk';
import { getValueID } from '../../utils/utils';

const Detail = () => {
    const { title } = useParams();
    const dispatch = useAppDispatch();
    let activity = useAppSelector(state => state.activities.activityDetail);
    const loading = useAppSelector(state => state.activities.loading);
    const relatedActivities = useAppSelector((state) => state.activities?.relatedActivities)

    useEffect(() => {
        if (title) {
            const id = getValueID(title)
            if (id) {
                dispatch(getDetailAndRelatedActivities(id));
            }
        }
    }, [title, dispatch]);

    if (loading === 'pending' || !activity) return <Loading />;

    return (
        <DetailContent
            content={activity}
            relatedContent={relatedActivities ?? []}
            titleRelatedContent='Actividades relacionadas'
            subPath='actividades'
        />
    );
};

export default Detail;