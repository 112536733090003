import '../styles.css'
import { useEffect, useRef } from "react";
import DialogLayout from "./DialogLayout";

interface IDialog {
    isOpen: boolean;
    toggle: () => void;
    children: React.ReactNode
}

const Dialog = ({ isOpen, toggle, children }: IDialog) => {
    const dialogRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const html: HTMLHtmlElement | null = document.querySelector('html');
        const handleClickOutside = (event: MouseEvent) => {
            if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
                toggle();
            }
        };

        if (isOpen) {
            document.body.style.overflow = "hidden";
            html!.style.overflow = "hidden";
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.body.style.overflow = "";
            html!.style.overflow = "";
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, toggle]);

    if (!isOpen) return null

    return (
        <DialogLayout onClose={toggle}>
            <div ref={dialogRef}>
                {children}
            </div>
        </DialogLayout>
    )
}

export default Dialog