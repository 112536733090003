import QuickAccess from '../Cards/AccessToRigths'
import QuickAccessButton from '../Cards/QuickAccessButton'
import { EvaluatedRights } from '../../interfaces/Rights'
import ResponsiveContainer from '../../layouts/ResponsiveContainer'
import { useNavigate } from 'react-router-dom'

const AccessToRigthsMapper = ({ quickAccessToRights }: { quickAccessToRights: EvaluatedRights[] }) => {
    const navigate = useNavigate();

    const handleButtonClickDisplay = (right: string) => {
        navigate(`visualizador-de-indicadores?right=${right}&page=1&limit=5`);
    };

    //agrega el periodo harcodeado, tiene que venir desde el derecho
    const handleButtonClickEvaluator = (right: string, rightId: number) => {
        navigate(`evaluador?right=${right}&rightId=${rightId}&periodo=${2023}`);
    };

    return (
        <ResponsiveContainer className="flex flex-wrap gap-6 pt-6">
            {
                quickAccessToRights.map((right) => (
                    <div key={right.id} className="w-full md:w-[26rem] lg:w-[26.75rem] xl:w-[34.5rem]">
                        {/* Card */}
                        <button disabled={true} className='w-full' onClick={() => handleButtonClickEvaluator(right.titulo, right.id)}        >
                            <QuickAccess
                                id={right.id}
                                color={right.color}
                                icono={right.icono}
                                icono_base64={right.icono_base64}
                                titulo={right.titulo}
                                estado={right.estado}
                                evaluaciones={right.evaluaciones}
                            />
                        </button>
                        {/* Buttons */}
                        <div className="flex flex-col gap-2 pt-2 ">
                            <QuickAccessButton
                                label='Evaluador'
                                disabled={true}
                                onClick={() => handleButtonClickEvaluator(right.titulo, right.id)}
                            />
                            <QuickAccessButton
                                label='Visualizador de indicadores'
                                disabled={true}
                                onClick={() => handleButtonClickDisplay(right.titulo)}
                            />
                        </div>
                    </div>
                ))
            }
        </ResponsiveContainer>
    )
}

export default AccessToRigthsMapper