import ChipState from '../../../../components/Chips/ChipState'
import { MdInfo, MdOutlineShare } from "react-icons/md";
import { useState } from 'react';
import Dialog from '../../../../components/Dialog/Dialog';
import Share from '../../../../components/Share/Share';
import MethodologyNoteDialog from './MethodologyNoteDialog';

interface IHeaderIndicator {
    breadcrumbs: string;
    titulo: string;
    descripcion: string;
    notas: string;
    documento_informativo: string;
    state: number | null;
    id: number
}

const HeaderIndicator = ({ breadcrumbs, titulo, descripcion, notas, documento_informativo, state, id }: IHeaderIndicator) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenMetodologyNote, setIsOpenMetodologyNote] = useState<boolean>(false);

    const parts = breadcrumbs ? breadcrumbs.split('>').map(part => part.trim()) : [];

    let href = window.location.href;

    if (!href.includes('search')) {
        href += `&type=${encodeURIComponent(parts[1])}`;
        href += `&dimension=${encodeURIComponent(parts[2].replaceAll(' ', '+'))}`;
        href += `&subdimension=${encodeURIComponent(parts[3].replaceAll(' ', '+'))}`;
        href += `&indicator=${encodeURIComponent(titulo.replaceAll(' ', '+'))}`;
        href += `&indicatorID=${id}`;
    }

    return (
        <>
            <p className='text-[#555C62] text-tmedium truncate'>
                {breadcrumbs.replaceAll('>', '/')}
                <span className='text-tmedium text-primary-40'> / {titulo}</span>
            </p>
            <div className='flex justify-between'>
                <h2 className='text-primary-40 text-hmedium'>{titulo}</h2>
                <div className='flex gap-2'>
                    <ChipState state={state} />
                    <button
                        className="flex items-center justify-center border rounded-md border-system-light-50 w-[46px] h-[40px] hover:brightness-[0.90] hover:shadow hover:shadow-gray-0/30"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <MdOutlineShare size={22} />
                    </button>
                    <button
                        className="flex items-center justify-center border rounded-md border-system-light-50 w-[46px] h-[40px] hover:brightness-[0.90] hover:shadow hover:shadow-gray-0/30"
                        onClick={() => setIsOpenMetodologyNote(!isOpenMetodologyNote)}
                    >
                        <MdInfo size={22} />
                    </button>
                </div>
            </div>
            <span className='text-system-light-40 blarge'>{descripcion}</span>
            <Dialog isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <Share
                    newsTitle={`Indicador de ${titulo}`}
                    newsUrl={href ? href : ''}
                    description={descripcion}
                />
            </Dialog>
            <MethodologyNoteDialog
                isOpen={isOpenMetodologyNote}
                toggle={() => setIsOpenMetodologyNote(!isOpenMetodologyNote)}
                notas={notas}
                documento_informativo={documento_informativo}
            />
        </>
    )
}

export default HeaderIndicator