import React, { useEffect, useState } from 'react';
import CustomTable from '../../../components/Tables/CustomTable';
import DynamicBannerCard from '../../../components/Cards/DynamicBannerCard';
import dashboard from '../../../assets/icons/dashboard.svg';
import { INDICATOR_TYPE_PROCESS } from '../../../utils/const';
import { Subdimensiones } from '../../../interfaces/Evaluations';

const EvaluationSubdimensionResAndProc = ({ selected_type_indicator, evaluation_subDimension }: { selected_type_indicator: string, evaluation_subDimension: Subdimensiones[] }) => {

    const [right, setRight] = useState('');
    const [rightId, setRightId] = useState('');

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const params = new URLSearchParams(window.location.search);

            setRight(params.get('right') || '');
            setRightId(params.get('rightId') || '');
        }
    }, []);

    const columns: { label: string; accessor: keyof Subdimensiones; render?: (value: any, row: Subdimensiones) => React.ReactNode }[] = [
        {
            label: 'Subdimensiones',
            accessor: 'nombre',
            render: (value: string, row: Subdimensiones) => {
                const colorClass = row?.indicadores?.length === 0 ? 'text-system-primary-light-40' : 'text-primary-40 cursor-pointer hover:underline';
                return <a  href={row?.indicadores?.length === 0 ? '#' : `/monitor/visualizador-de-indicadores?right=${right}&typeIndicator=${selected_type_indicator}&subdimension=${row.nombre}&page=1&limit=5`}
                    className={`${colorClass} text-blarge`}
                    style={row?.indicadores?.length === 0 ? { pointerEvents: "none", color: "gray", cursor: "not-allowed", textDecoration: "none" } : {}}
                >
                    {value}
                </a>;
            }
        },
        {
            label: 'Indicadores',
            accessor: 'indicadores',
            render: (value: Subdimensiones['indicadores']) => (
                <span className='text-system-light-40'>{value?.length}</span>
            )
        },
        {
            label: 'Estado',
            accessor: 'trivalente_subdimension',
            render: (value: Subdimensiones['trivalente_subdimension']) => {
                let color;
                if (value === -1) color = 'bg-indicator-red';
                else if (value === 0) color = 'bg-indicator-yellow';
                else if (value === 1) color = 'bg-indicator-green';
                else color = 'bg-indicator-null';

                return <span className={`inline-block w-3 h-3 rounded-full ${color}`} />;
            },
        },
    ];

    return (
        <div className='mt-6'>
            <CustomTable
                columns={columns}
                data={evaluation_subDimension}
                colorTr={selected_type_indicator === INDICATOR_TYPE_PROCESS ? 'text-primary-100' : 'text-primary-10'}
                bgColorTr={selected_type_indicator === INDICATOR_TYPE_PROCESS ? 'bg-primary-200' : 'bg-[#FFBD8B]'}
            />
            <div className='mt-10'></div>
            <DynamicBannerCard
                colorTitle='text-primary-10'
                title='Metodología de evaluación'
                text='Conocé los criterios y los pasos que seguimos para realizar la evaluación del derecho y sus dimensiones'
                colorText='system-light-40'
                icon={dashboard}
                buttonLabel='Ver'
                buttonUrl={`/monitor/metodologia/${rightId}`}
                bgColor="#DCE4E9"
                colorPolygon='#001F29'
                classNameButton='bg-primary-40 text-primary-100'
            />
        </div>
    );
}

export default EvaluationSubdimensionResAndProc;