import React from 'react';
import StackedBarChart from '../../../../components/Charts/StackedBarChart';
import CompositeBarsCharts from '../../../../components/Charts/CompositeBarsCharts';
import PieCharts from '../../../../components/Charts/PieCharts';
import LineCharts from '../../../../components/Charts/LineCharts';
import { ChartData, GraphicsDomain } from '../../../../interfaces/Indicator';
import BarChartt from '../../../../components/Charts/BarChart';

interface IndicatorGraphs {
  typeOfGraph: 1 | 2 | 3 | 4 | 5;
  data: ChartData[];
  chartRef: React.RefObject<HTMLDivElement>,
  typeOfIndicator: string;
  variable_analisis: string[];
  domain?: GraphicsDomain | null;
}

const GRAFICO_BARRA = 1;
const GRAFICO_BARRA_APILABLE = 2;
const GRAFICO_BARRA_AGRUPADA = 3;
const GRAFICO_TORTA = 5;
const GRAFICO_LINEAL = 4;

const IndicatorGraphs = ({ typeOfGraph, data, chartRef, typeOfIndicator, variable_analisis, domain }: IndicatorGraphs) => {
  switch (typeOfGraph) {
    case GRAFICO_BARRA:
      return <>
        <BarChartt
          data={data}
          typeOfIndicador={typeOfIndicator}
          variable_analisis={variable_analisis}
          ref={chartRef}
          range={domain}
        />
      </>;
    case GRAFICO_BARRA_APILABLE:
      return <StackedBarChart
        data={data}
        ref={chartRef}
        variable_analisis={variable_analisis}
        range={domain}
      />;
    case GRAFICO_BARRA_AGRUPADA:
      return <CompositeBarsCharts
        data={data}
        variable_analisis={variable_analisis}
        ref={chartRef}
        range={domain}
      />;
    case GRAFICO_TORTA:
      return <PieCharts
        data={data}
        ref={chartRef}
      />;
    case GRAFICO_LINEAL:
      return <LineCharts
        data={data}
        typeOfIndicador={typeOfIndicator}
        variable_analisis={variable_analisis}
        ref={chartRef}
        range={domain}
      />;
    default:
      return <></>;
  }
};

export default IndicatorGraphs;
