import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getArithmeticalEvaluation, getEvaluationRight } from './evaluatorThunk';
import { ArithmeticalEvaluation, DimensionEvaluation, EvaluatedRight, IndicatorTypeEvaluation, Subdimensiones } from '../../../interfaces/Evaluations';

interface EvaluatorState {
    loading: "idle" | "pending" | "succeeded" | "failed";
    loading_arithmetical: "idle" | "pending" | "succeeded" | "failed";
    evaluation_right: EvaluatedRight | null;
    errors: string;
    arithmetical_evaluation_right: ArithmeticalEvaluation[] | null;
    trivalent_evaluation_right: -1 | 0 | 1 | null,
    periodsToEvaluate: { id: number, value: string }[] | null
    evaluation_indicator_type: IndicatorTypeEvaluation[] | null
    evaluation_dimension: DimensionEvaluation[] | null
    evaluation_subdimension: Subdimensiones[] | null
    //selected
    selected_type_indicator: string | null
    selected_dimension: string | null
}

const initialState: EvaluatorState = {
    loading: "idle",
    loading_arithmetical: "idle",
    evaluation_right: null,
    errors: '',
    arithmetical_evaluation_right: null,
    trivalent_evaluation_right: null,
    periodsToEvaluate: null,
    evaluation_indicator_type: null,
    evaluation_dimension: null,
    evaluation_subdimension: null,
    //selected
    selected_type_indicator: null,
    selected_dimension: null
}

export const evaluatorSlice = createSlice({
    name: 'evaluator',
    initialState,
    reducers: {
        evaluationDimension: (state, action: PayloadAction<{ typeOfIndicator: number }>) => {
            if (!action.payload.typeOfIndicator) {
                return
            }

            const copyTypeOfIndicator: IndicatorTypeEvaluation[] = JSON.parse(JSON.stringify(state.evaluation_indicator_type))

            if (!copyTypeOfIndicator.length) return;

            const findTypeOfIndicator = copyTypeOfIndicator.find(element => element.id === action.payload.typeOfIndicator)
            if (!findTypeOfIndicator) return;

            state.evaluation_dimension = findTypeOfIndicator.dimensiones;
        },
        evaluationSubDimension: (state, action: PayloadAction<{ dimensionId: number }>) => {
            if (!action.payload.dimensionId) return;

            const copyDimension: DimensionEvaluation[] = JSON.parse(JSON.stringify(state.evaluation_dimension));
            if (!copyDimension.length) return;

            const findDimension = copyDimension.find(element => element.id === action.payload.dimensionId)
            if (!findDimension) return;

            state.evaluation_subdimension = findDimension.subdimensiones;
        },
        selectedTypeIndicator: (state, action: PayloadAction<{ typeOfIndicator: string }>) => {
            if (!action.payload.typeOfIndicator) return

            state.selected_type_indicator = action.payload.typeOfIndicator;
            state.selected_dimension = null
        },
        selectedDimension: (state, action: PayloadAction<{ dimension: string }>) => {
            if (!action.payload.dimension) return

            state.selected_dimension = action.payload.dimension;
        },
        clearEvaluation: (state) => {
            state.evaluation_right = null
            state.arithmetical_evaluation_right = null
            state.selected_type_indicator = null;
        },
        clearTypeOfIndicator: (state) => {
            state.selected_type_indicator = null;
        },
        clearDimensionEvaluations: (state) => {
            state.evaluation_dimension = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEvaluationRight.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getEvaluationRight.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.evaluation_right = action.payload.evaluation_right;
            state.trivalent_evaluation_right = action.payload.evaluation_right ? action.payload.evaluation_right?.trivalente_derecho : null
            state.evaluation_indicator_type = action?.payload?.evaluations_indicator_type
                ? action?.payload?.evaluations_indicator_type.sort((a, b) => a.nombre.localeCompare(b.nombre))
                : null;
        });
        builder.addCase(getEvaluationRight.rejected, (state, action) => {
            state.loading = "failed";
            state.errors = action.payload?.message || 'Error desconocido';
        });

        builder.addCase(getArithmeticalEvaluation.pending, (state) => {
            state.loading_arithmetical = "pending";
        });
        builder.addCase(getArithmeticalEvaluation.fulfilled, (state, action) => {
            state.loading_arithmetical = "succeeded";
            state.arithmetical_evaluation_right = [action.payload.arithmetical_evaluation_right];
            state.periodsToEvaluate = action.payload.periodsToEvaluate;
        });
        builder.addCase(getArithmeticalEvaluation.rejected, (state, action) => {
            state.loading_arithmetical = "failed";
            state.errors = action.payload?.message || 'Error desconocido';
        });
    },
})

export const {
    clearEvaluation,
    evaluationDimension,
    selectedTypeIndicator,
    selectedDimension,
    evaluationSubDimension,
    clearTypeOfIndicator,
    clearDimensionEvaluations
} = evaluatorSlice.actions

export default evaluatorSlice.reducer