import Hero from '../../components/Hero/Hero'
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs"
import visualizador from "../../assets/img/icono-visualizador.png"
import SearchAndFilter from './components/SearchAndFilters/SearchAndFilter'
import IndicatorMapper from '../../components/Mappers/IndicatorMapper'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useEffect } from 'react'
import { getIndicators } from '../../redux/slices/indicatorDisplay/indicator/indicatorThunk'
import { useLocation, useNavigate } from 'react-router-dom'
import SEO from '../../components/SEO/SEO'
import { clearIndicators } from '../../redux/slices/indicatorDisplay/indicator/indicatorSlice'
import Loading from '../../components/Loading/Loading'
import Pagination from '../../components/Pagination/Pagination'
import CTABox from '../../components/Cards/CTABox'
import { MdArrowForward } from 'react-icons/md'

const Index = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const { indicators, pagination, loading } = useAppSelector((state) => state.indicator)
    const { currentPage, totalPages, totalItems } = pagination;

    // Este useEffect solo se ejecuta cuando indicators.length es 0, es decir, en la carga inicial.
    useEffect(() => {
        const right = params.get('right') || "";
        const search = params.get('search') || '';
        const indicatorID = params.get('indicatorID') || '';
        const typeIndicator = params.get('typeIndicator') || '';
        const subdimension = params.get('subdimension') || '';
        const page = parseInt(params.get('page') || '1', 10);
        const limit = parseInt(params.get('limit') || '5', 10);

        if (!indicators || indicators.length === 0) {
            dispatch(getIndicators({
                right,
                search,
                typeIndicator: typeIndicator,
                subdimensionId: subdimension,
                page,
                limit,
                indicadorId: indicatorID
            }));
        }
    }, [dispatch, location.search]);

    useEffect(() => {
        return () => {
            dispatch(clearIndicators())
        }
    }, [])

    const handlePageChange = (page: number) => {
        const params = new URLSearchParams(location.search);
        params.set('page', page.toString());
        navigate({ search: params.toString() });
        dispatch(getIndicators({
            right: params.get('right') || "",
            subdimensionId: params.get('subdimension') || '',
            typeIndicator: params.get('typeIndicator') || '',
            page,
            limit: parseInt(params.get('limit') || '5', 10)
        }));
    };

    return (
        <>
            <SEO
                title='Visualizador de indicadores'
                description='Visualizador de indicadores'
                type='website'
            />
            <Hero
                breadcrumbs={<Breadcrumbs />}
                title={"Visualizador de indicadores"}
                paragraph={"Nuestro visualizador está diseñado para que puedas sumergirte en el detalle de cada uno de los indicadores que monitoreamos y las variables que los componen.  A través de distintos formatos, contribuye a la transparencia de los datos oficiales y te brinda la posibilidad de conocer con más profundidad la situación de los derechos en la Ciudad Autónoma de Buenos Aires."}
                withShape={true}
                layout="row"
                media={{ type: 'image', src: visualizador, url: '', alt: 'visualizador', className: '' }}
            />
            <SearchAndFilter />
            {
                loading === 'pending' ?
                    <Loading
                        color='#0E6681'
                        size={40}
                        className='h-[300px]'
                    />
                    : (<>
                        {
                            indicators && indicators?.length > 0 ? (
                                <>
                                    <IndicatorMapper
                                        indicators={indicators ? indicators : []}
                                        totalItems={totalItems}
                                    />
                                    <Pagination
                                        data={indicators || []}
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        setCurrentPage={handlePageChange}
                                    />

                                </>
                            ) : (
                                <div className="text-center mt-8">No se encontraron indicadores.</div>
                            )
                        }
                    </>
                    )
            }
            <CTABox
                title="¿Tenés dudas o consultas?"
                linkPath="/monitor/contactanos"
                buttonLabel="Contactanos"
                buttonIcon={MdArrowForward}
                background="bg-[#D7D9E5]/30 "
            />
        </>
    )
}

export default Index
