import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Hero from '../../components/Hero/Hero'
import SearchBar from '../../components/SearchBar/SearchBar'
import Filters from './components/Filters'
import MediaAndContentMapperResearch from '../../components/Mappers/MediaAndContentMapperResearch'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { getResearchs, getCategories } from '../../redux/slices/researchs/researchsThunk'
import Loading from '../../components/Loading/Loading'
import Pagination from '../../components/Pagination/Pagination'
import { useLocation, useNavigate } from 'react-router-dom'
import ResponsiveContainer from '../../layouts/ResponsiveContainer'
import SEO from '../../components/SEO/SEO'
import { clearResearchs } from '../../redux/slices/researchs/researchsSlice'
import { MdArrowForward } from 'react-icons/md'
import CTABox from '../../components/Cards/CTABox'
import withoutPubli from '../../assets/img/IMG inv y Acciones.png'

const DEFAULT_MIN_DATE = '2024-01-01';

const Index = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // Obtención de datos del estado global de Redux
    const { researchs, pagination, loading, categoriesOptions } = useAppSelector((state) => state.researchs)
    const { currentPage, totalPages, totalItems } = pagination;

    // Estados locales para filtros y fechas
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [categorieSelected, setCategorieSelected] = useState<string>('');
    const [dateFrom, setDateFrom] = useState<string>('');
    const [dateTo, setDateTo] = useState<string>('');
    const [disabledPeriodFrom, setDisabledPeriodFrom] = useState<boolean>(false);
    const [disabledPeriodTo, setDisabledPeriodTo] = useState<boolean>(true);
    const [minPeriodDateFrom, _setMinPeriodDateFrom] = useState<string>(DEFAULT_MIN_DATE);
    const [minPeriodDateTo, setMinPeriodDateTo] = useState<string>('');
    const [maxPeriodDateFrom, setMaxPeriodDateFrom] = useState<string>('');
    const [maxPeriodDateTo, setMaxPeriodDateTo] = useState<string>('');
    const [disabledSearch, setDisabledSearch] = useState<boolean>(false);
    const [disabledCategories, setDisabledCategories] = useState<boolean>(false);

    // Efecto para cargar categorías y establecer límite de fecha máximo
    useEffect(() => {
        if (!categoriesOptions?.length) {
            dispatch(getCategories())
        }
        maxDateLimit();
    }, [categoriesOptions?.length])

    // Efecto para manejar cambios en la URL y actualizar filtros
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const query = params.get('search') || '';
        const page = parseInt(params.get('page') || '1', 10);
        const categorie = params.get('categorie') || '';
        const dateTo = params.get('to') || "";
        const dateFrom = params.get('from') || "";

        // Actualización de los estados con los parámetros de la URL
        if (categorie) setCategorieSelected(categorie);
        if (dateFrom) setDateFrom(dateFrom);
        if (dateTo) { setDateTo(dateTo); setDisabledPeriodTo(false) };
        if (query) setSearchQuery(query);

        // Despacho de la acción para obtener actividades basadas en los parámetros de la URL
        dispatch(getResearchs({ categoria: categorie, page, search: query, fechaTo: dateTo, fechaFrom: dateFrom }));
    }, [dispatch, location.search]);

    useEffect(() => {
        if (!researchs) {
            setDisabledPeriodFrom(true);
            setDisabledSearch(true);
            setDisabledCategories(true);
        }
    }, [researchs])

    useEffect(() => {
        return () => {
            dispatch(clearResearchs())
        }
    }, [])

    // Manejo de cambio de página en la paginación
    const handlePageChange = (page: number) => {
        const params = new URLSearchParams(location.search);
        searchQuery && params.set('search', searchQuery);
        params.set('page', page.toString());
        navigate({ search: params.toString() });
    };

    // Manejo de búsqueda
    const handleSearch = (query: string) => {
        const params = new URLSearchParams(location.search);
        setSearchQuery(query);
        query ? params.set('search', query) : params.delete('search');
        params.set('page', '1');
        navigate({ search: params.toString() });
    };

    // Manejo de selección de categoría
    const onSelect = (value: string) => {
        const params = new URLSearchParams(location.search);
        setCategorieSelected(value !== 'Todas' ? value : '');
        value !== 'Todas' ? params.set('categorie', value) : params.delete('categorie');
        navigate({ search: params.toString() });
    };

    // Manejo de cambio en la fecha "Desde"
    const onHandleChangePeriodFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
        const date = e.target.value;
        const params = new URLSearchParams(location.search);
        if (date === "") {
            setDisabledPeriodTo(true);
            setDateTo('');
            setDateFrom('');
            params.delete('from');
            params.delete('to');
        } else {
            setDateFrom(date);
            setDateTo('');
            setDisabledPeriodTo(false);
            setMinPeriodDateTo(e.target.value)
        }
        navigate({ search: params.toString() });
    }

    // Manejo de cambio en la fecha "Hasta"
    const onHandleChangePeriodTo = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dateTo = e.target.value;
        const params = new URLSearchParams(location.search);
        if (dateTo !== '') {
            setDateTo(e.target.value);
            params.set('from', dateFrom);
            params.set('to', dateTo);
            params.set('page', '1');
        } else {
            setDateTo('');
            setDateFrom('');
            params.delete('from');
            params.delete('to');
        }
        navigate({ search: params.toString() });
    }

    // Función para establecer el límite máximo de la fecha al día actual
    const maxDateLimit = () => {
        const currentDay = new Date().toISOString().slice(0, 10);
        setMaxPeriodDateFrom(currentDay);
        setMaxPeriodDateTo(currentDay);
    };

    //Arma objetos para pasar props al componente Filter
    const dateProps = {
        onHandleChangePeriodFrom,
        onHandleChangePeriodTo,
        disabledPeriodFrom,
        disabledPeriodTo,
        minPeriodDateFrom,
        maxPeriodDateFrom,
        minPeriodDateTo,
        maxPeriodDateTo,
        valueDateFrom: dateFrom,
        valueDateTo: dateTo,
    };

    const categoryProps = {
        options: categoriesOptions ? categoriesOptions : [],
        numberOfArticles: totalItems,
        onSelect,
        selectedItem: categorieSelected,
        defaultSelectedItem: 'Todas',
        label: 'Categorías',
        disabledCategories
    };

    return (
        <>
            <SEO
                title='Investigaciones y acciones'
                description='Investigaciones y acciones realizadas por el monitor de derechos de CABA'
                type='article'
            />
            <Hero
                breadcrumbs={<Breadcrumbs />}
                title='Investigaciones y acciones'
                paragraph='Acá vas a encontrar las investigaciones y estudios que desarrollamos sobre los derechos monitoreados y otros trabajos más específicos, con la mirada puesta en relevar vulneraciones sistemáticas y efectuar recomendaciones de política pública.'
                media={{ type: '', src: '', url: '', alt: '', className: '' }}
                withShape={false}
                layout='row'
            />
            <div className='flex flex-col items-center'>
                <ResponsiveContainer className='flex flex-col justify-center' >
                    <div className='mt-16'>
                        <SearchBar
                            placeholder='Buscar  investigaciones y acciones'
                            onSearch={handleSearch}
                            defaultValue={searchQuery}
                            disabledSearch={disabledSearch}
                        />
                        <Filters
                            dateProps={dateProps}
                            categoryProps={categoryProps}
                        />
                    </div>
                    {loading == 'pending' ? (
                        <Loading
                            color='#0E6681'
                            size={40}
                            className='h-[300px]'
                        />
                    ) : (
                        <>
                            {researchs?.length === 0 ? (

                                <div className="flex items-center justify-center mt-12 mb-2">
                                    <div className='w-full sm:w-[536px] flex items-center flex-col'>
                                        <p className='text-blarge text-system-light-50 text-center mb-5'>Aún no hay publicaciones en esta sección, pero muy pronto compartiremos investigaciones y análisis sobre temas clave.</p>
                                        <img src={withoutPubli} alt="Aún no hay publicaciones" />
                                    </div>
                                </div>

                            ) : (
                                <>
                                    <MediaAndContentMapperResearch data={researchs} viewType='large' />
                                    <Pagination
                                        data={researchs || []}
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        setCurrentPage={handlePageChange}
                                    />
                                </>
                            )}
                        </>
                    )}
                    <CTABox
                        title="¿Tenés dudas o consultas?"
                        linkPath="/monitor/contactanos"
                        buttonLabel="Contactanos"
                        buttonIcon={MdArrowForward}
                        background="bg-[#D7D9E5]/30 "
                    />
                </ResponsiveContainer>
            </div>
        </>
    )
}

export default Index