import { MdOutlineSearch } from "react-icons/md";
import Button from "../Buttons/Button";
import { useEffect, useState } from "react";

interface ISearchBar {
    onSearch: (query: string) => void;
    defaultValue: string;
    placeholder: string;
    disabledSearch?: boolean;
}

const SearchBar = ({ onSearch, defaultValue, placeholder, disabledSearch = false }: ISearchBar) => {
    const [valueSearch, setValueSearch] = useState<string>(defaultValue);

    useEffect(() => {
        setValueSearch(defaultValue);
    }, [defaultValue]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValueSearch(e.target.value);
    };

    const onClick = () => {
        onSearch(valueSearch);
    }

    return (
        <>
            <div className='flex items-center gap-2 h-[3.5rem] border border-system-light-50 rounded-sm px-4'>
                <input
                    className='text-blarge text-system-light-40 w-full h-full rounded-sm'
                    placeholder={placeholder}
                    id='search'
                    name='search'
                    value={valueSearch}
                    onChange={handleSearchChange}
                    aria-label='Buscar actividades'
                />
                <Button
                    label=''
                    icon={MdOutlineSearch}
                    showIconLeft={false}
                    showIconRight={true}
                    className="bg-primary-40 text-primary-100"
                    aria-label='Botón de búsqueda'
                    onClick={onClick}
                    disabled={disabledSearch}
                />
            </div>
        </>
    );
};

export default SearchBar;