import React from 'react'
import Dialog from '../Dialog/Dialog'
import { MdNotificationImportant } from "react-icons/md";

const Warning = ({ isOpen, setIsOpen }: { isOpen: boolean, setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {

  return (
    <Dialog
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}>
      <div className='flex gap-3 flex-col'>
        <div className='flex flex-col items-center'>
          <MdNotificationImportant size={32} color='#0E6681' />
          <p className='text-hsmall'>¡Atención!</p>
        </div>
        <span className='text-bmedium text-system-light-40'>Como se advierte en cada caso, algunos indicadores no fueron evaluados en 2023 porque se encuentra pendiente la publicación de información oficial o la respuesta de las autoridades correspondientes. Cuando se disponga de estos datos se procederá a su actualización.</span>
        <span className='text-bmedium text-system-light-40'> Te invitamos a navegar por el Monitor.</span>
      </div>
    </Dialog>
  )
}

export default Warning