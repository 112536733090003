import { useEffect, useState } from 'react';

export const useAutoModal = (delayMs: number = 60000) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const openModal = () => setIsModalOpen(true);

        const timeoutId = setTimeout(() => {
            openModal();
        }, delayMs);

        return () => clearTimeout(timeoutId);
    }, [delayMs]);

    return { isModalOpen, setIsModalOpen };
};
