import Dialog from '../../../../components/Dialog/Dialog'
import { MdInfo } from 'react-icons/md'
import DownloadPDFWithFetch from '../../../../components/DownloadPDF/DownloadPDFWithFetch'
import { Parser } from 'html-to-react'

interface IMethodologyNoteDialog {
    isOpen: boolean,
    toggle: () => void,
    notas: string,
    documento_informativo: string
}

const MethodologyNoteDialog = ({ isOpen, toggle, notas, documento_informativo }: IMethodologyNoteDialog) => {
    return (
        <Dialog isOpen={isOpen} toggle={toggle}>
            <div className='flex gap-3 flex-col'>
                <div className='flex flex-col items-center'>
                    <MdInfo size={22} color='#0E6681' />
                    <p className='text-hsmall'>Nota metodológica</p>
                </div>
                <div className='prose text-bmedium text-system-light-40'>{Parser().parse(notas)}</div>
                {documento_informativo && documento_informativo.trim() !== '' && (
                    <>
                        <span className='text-bmedium text-primary-40'>
                            ¿Querés conocer más sobre esta metodología?, hacé click en el siguiente botón.
                        </span>
                        <div className='flex items-center justify-center'>
                            <DownloadPDFWithFetch
                                fileName={documento_informativo}
                                label='Descargar PDF'
                                className='bg-primary-40 text-primary-100'
                            />
                        </div>
                    </>
                )}
            </div>
        </Dialog>
    )
}

export default MethodologyNoteDialog