import * as XLSX from 'xlsx';
import { Graphics } from '../../../interfaces/Indicator';

const exportToExcel = (data: Graphics, source: string, fileName: string, filtersSelected: { [key: string]: string }) => {
    if (!data) {
        return;
    }

    // Obtiene los encabezados
    const headers = Object.keys(data.data_formated[0]);

    const updatedHeaders = headers.map(header => header === 'name' ? 'Año' : header);

    const worksheetData = [
        updatedHeaders,
        ...data.data_formated.map(item => updatedHeaders.map(header => {
            const originalHeader = header === 'Año' ? 'name' : header;
            return item[originalHeader] || '';
        }))
    ];

    // Agrega una fila vacía para espacio entre filtros y data
    worksheetData.push([]);

    if (filtersSelected && Object.keys(filtersSelected).length > 0) {
        worksheetData.push(["Filtros seleccionados"]);
        const filtersTextLines = Object.entries(filtersSelected).map(
            ([key, value]) => [`${key}: ${value}`]
        );
        worksheetData.push(...filtersTextLines);
    }
    if (data.nota_grafico && data.nota_grafico?.length) worksheetData.push([`Nota: ${data.nota_grafico}`]);
    if (source && source.length) worksheetData.push([`Fuente: ${source}`]);

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");

    XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export default exportToExcel;