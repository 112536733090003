import EvaluationStates from "../States/EvaluationStatesCircle";
import { DimensionEvaluation } from "../../interfaces/Evaluations";

interface TabsProps {
    tabs: DimensionEvaluation[];
    selectedIndex: number | null;
    onTabClick: (index: number) => void;
}
const Tabs = ({ tabs, selectedIndex, onTabClick }: TabsProps) => {
    return (
        <div className="flex gap-5 mt-3 border-b border-system-primary-20">
            {tabs.map((tab) => (
                <button
                    className={`flex items-center gap-2 p-4 ${selectedIndex === tab.id ? 'border-b-2 border-primary-40' : 'hover:bg-[#EDEDED]'}`}
                    key={tab.id}
                    onClick={() => onTabClick(tab.id)}
                >
                    <EvaluationStates
                        status={tab.trivalente_dimension}
                        size={12}
                    />
                    <span className={`text-llarge ${selectedIndex === tab.id ? 'text-system-light-100' : 'text-system-light-40'}`}>
                        {tab.nombre}
                    </span>
                </button>
            ))}
        </div>
    )
}

export default Tabs