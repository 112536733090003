import QuickAccess from '../Cards/AccessToRigths';
import QuickAccessButton from '../Cards/QuickAccessButton';
import { EvaluatedRights } from '../../interfaces/Rights';
import ResponsiveContainer from '../../layouts/ResponsiveContainer';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

const AccessToRigthsMapper = ({ quickAccessToRights, initialSelectedPeriod }: { initialSelectedPeriod: string, quickAccessToRights: EvaluatedRights[] }) => {
    const [selectedPeriod, setSelectedPeriod] = useState(initialSelectedPeriod);
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedPeriod(initialSelectedPeriod);
    }, [initialSelectedPeriod]);

    // Navega al visualizador de indicadores
    const handleButtonClickDisplay = (right: string) => {
        navigate(`visualizador-de-indicadores?right=${right}&period=${Number(selectedPeriod)}&page=1&limit=5`);
    };

    // Navega al evaluador
    const handleButtonClickEvaluator = (right: string, rightId: number) => {
        navigate(`evaluador?right=${right}&rightId=${rightId}&periodo=${Number(selectedPeriod)}`);
    };

    return (
        <ResponsiveContainer className="flex flex-wrap gap-6 pt-6">
            {quickAccessToRights?.length > 0 && quickAccessToRights.map((right) => {
                const evaluaciones = right.evaluaciones || {}; // Asegura que evaluaciones sea un objeto
                const periodData = evaluaciones[selectedPeriod] || {}; // Datos del período seleccionado
                const { evaluacion_trivalente, suma_aritmetica } = periodData;

                return (
                    <div key={right.id} className="w-full md:w-[26rem] lg:w-[26.75rem] xl:w-[34.5rem]">
                        {/* Tarjeta de Acceso */}
                        <button
                            className='w-full'
                            disabled={true}
                            onClick={(e) => {
                                e.stopPropagation(); // Previene la propagación del evento de clic
                                if (evaluacion_trivalente !== null || suma_aritmetica !== null) {
                                    handleButtonClickEvaluator(right.titulo, right.id);
                                }
                            }}
                        >
                            <QuickAccess
                                id={right.id}
                                color={right.color}
                                icono={right.icono}
                                icono_base64={right.icono_base64}
                                titulo={right.titulo}
                                estado={right.estado}
                                evaluaciones={right?.evaluaciones}
                                periodSelected={selectedPeriod}
                            />
                        </button>
                        {/* Botones */}
                        <div className="flex flex-col gap-2 pt-2">
                            <QuickAccessButton
                                label='Evaluador'
                                disabled={evaluacion_trivalente === null && suma_aritmetica === null}
                                onClick={() => handleButtonClickEvaluator(right.titulo, right.id)}
                            />
                            <QuickAccessButton
                                label='Visualizador de indicadores'
                                disabled={false}
                                onClick={() => handleButtonClickDisplay(right.titulo)}
                            />
                        </div>
                    </div>
                );
            })}
        </ResponsiveContainer>
    );
};

export default AccessToRigthsMapper;