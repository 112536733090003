import { Subdimensiones } from '../../interfaces/Evaluations';
import EvaluationStates from '../States/EvaluationStatesCircle';
import EvaluationLaws from '../States/EvaluationLaws';

const FixedTable = ({ evaluation_subDimension, period }: { evaluation_subDimension: Subdimensiones[], period: string }) => {
    const allYears = Array.from(
        new Set(
            evaluation_subDimension.flatMap((row) =>
                row.evaluaciones ? Object.keys(row.evaluaciones) : []
            )
        )
    ).sort((a, b) => parseInt(b) - parseInt(a));

    // Función para obtener las leyes de cada año en orden descendente
    const allLawsByYear = (year: string) => {
        const laws = new Map<string, { law: string; url: string | null }>();

        evaluation_subDimension.forEach((row) => {
            row.evaluaciones?.[year]?.forEach((evaluation) => {
                laws.set(evaluation.nombre, { law: evaluation.nombre, url: evaluation.url || null });
            });
        });

        return Array.from(laws.values()).sort((a, b) => b.law.localeCompare(a.law));
    };

    return (
        <div className="flex w-full border rounded-lg border-system-light-50 ">
            {/* Tabla fija de subdimensiones y estado */}
            <div className="w-[350px]">
                <div className="w-[350px]">
                    <div className="text-llarge text-primary-100 bg-primary-200 px-4 py-4 flex justify-between rounded-tl-lg">
                        <span className="header-cell">SUBDIMENSIONES</span>
                        <span className='w-[55px]'>ESTADO</span>
                    </div>
                </div>

                <div className="w-[350px] bg-system-light-10">
                    {evaluation_subDimension.map((row, rowIndex) => {
                        const isLongText = row.nombre.length > 61;
                        const displayText = isLongText ? `${row.nombre.substring(0, 61)}...` : row.nombre;

                        return (
                            <div key={rowIndex} className="border-t border-system-light-50">
                                <div className="flex gap-3 items-center px-4">
                                    <p
                                        className='flex items-center text-bmedium w-[263px] h-[56px] text-system-light-40 p-1 line-clamp-3'
                                        title={isLongText ? row.nombre : ''}
                                    >
                                        {displayText}
                                    </p>
                                    <div className='w-[55px] flex justify-center items-center h-[56px]'>
                                        <EvaluationStates
                                            status={row.trivalente_subdimension}
                                            size={12}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* Tabla desplazable para mostrar las leyes y evaluaciones */}
            <div className="scrollable-table overflow-x-auto w-full max-w-full rounded-r-lg">
                <div className="bg-primary-10 flex  h-[52px] gap-4 items-center min-w-fit ">
                    {/* Encabezado de años y leyes */}
                    {allYears.flatMap((year) =>
                        allLawsByYear(year).map((law, lawIndex) => {
                            const isLongText = law.law.length > 25
                            const displayText = isLongText ? `${law.law.substring(0, 25)}...` : law.law;

                            return (
                                <div key={`${year}-${lawIndex}`} className={`w-[175px] justify-center flex flex-col items-center ${period === year ? 'text-[#F5A361]' : 'text-indicator-null'}`}>
                                    {law.url ? (
                                        <a
                                            href={law.url}
                                            className="text-tsmall text-center w-[175px] underline hover:underline"
                                            title={isLongText ? law.law : ''}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {displayText}
                                        </a>
                                    ) : (
                                        <span
                                            className="text-tsmall text-center w-[175px]"
                                            title={isLongText ? law.law : ''}
                                        >
                                            {displayText}
                                        </span>
                                    )}
                                    <p className="text-tsmall font-semibold text-center w-[175px]">{year}</p>
                                </div>
                            )
                        }))}
                </div>
                <div className="scrollable-body flex flex-col">
                    {evaluation_subDimension.map((row, rowIndex) => (
                        <div key={rowIndex} className="flex flex-row gap-4 border-t border-system-light-50 min-w-fit">
                            {allYears.map((year, yearIndex) => (
                                <div key={yearIndex} className="flex gap-4">
                                    {allLawsByYear(year).map((law, lawIndex) => {
                                        const evaluation = row.evaluaciones?.[year]?.find(
                                            (evalItem) => evalItem.nombre === law.law
                                        );
                                        return (
                                            <div key={lawIndex} className=" w-[175px] h-[56px] flex flex-col justify-center items-center">
                                                <EvaluationLaws
                                                    evaluacion={evaluation ? evaluation.evaluacion : null}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FixedTable;
