import React, { useState, useRef, useEffect } from 'react';
import { IoMdArrowDropdown } from "react-icons/io";
import { MdOutlineCheck } from "react-icons/md";

export interface CustomSelectsOption {
    value: string;
    id: number | string;
}

export interface ICustomSelects {
    options: CustomSelectsOption[];
    onSelect: (selectedValue: string, label?: string) => void;
    selectedItem: string; // Optional prop for pre-selecting an item
    defaultSelectedItem: string;
    label: string; // Label for the CustomSelects
    disabled?: boolean
    className?: string
}

const CustomSelects: React.FC<ICustomSelects> = ({ options, onSelect, selectedItem, defaultSelectedItem, label, disabled, className }) => {
    const [isOpen, setIsOpen] = useState(false);
    const CustomSelectsRef = useRef<HTMLDivElement>(null);

    const handleClick = (option: CustomSelectsOption) => {
        if (onSelect.length > 1) {
            // Si la función onSelect tiene más de un argumento, pasar ambos label y value
            onSelect(label, option.value,);
        } else {
            // Si solo espera el value, pasarlo solo
            onSelect(option.value);
        }
        setIsOpen(false);
    };

    const openCustomSelects = () => {
        if (!disabled) {
            setIsOpen(!isOpen)
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!disabled) {
            if (event.key === 'Escape') {
                setIsOpen(false);
            } else if (event.key === 'ArrowDown' && !isOpen) {
                setIsOpen(true);
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (CustomSelectsRef.current && !CustomSelectsRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isOpen]);

    return (
        <div ref={CustomSelectsRef} className={`relative ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${className}`} onKeyDown={handleKeyDown}>
            {label && <p className='text-llarge text-system-light-40 ps-2 pb-1'>{label}</p>}
            <div
                className={`h-[32px] flex items-center justify-between gap-1 md:gap-2 px-2 py-1 ${!selectedItem ? 'border rounded-md border-system-light-50' : 'bg-system-secondary-10 rounded-md'
                    }`}
                tabIndex={isOpen ? 0 : -1} // Allow focus when open
                aria-labelledby={`${label}-label`} // Assuming label prop exists
                aria-expanded={isOpen} // Indicate open/closed state
            >
                <div className='flex gap-1 h-full items-center w-[85%]'>
                    {selectedItem && <MdOutlineCheck />}
                    <span className={`text-llarge truncate w-full ${!selectedItem ? 'text-system-light-40' : 'text-secondary-10'}`}>
                        {selectedItem || defaultSelectedItem || ''}
                    </span>
                </div>
                <button className='bg-transparent' onClick={openCustomSelects}>
                    <IoMdArrowDropdown />
                </button>
            </div>
            {isOpen && (
                <div className='absolute w-full rounded-lg shadow shadow-gray-0/30 bg-primary-100 z-40 max-h-64 overflow-y-scroll' style={{ width: '100%' }}>
                    <div className='flex flex-col'>
                        {options.map((option, index) => (
                            <span
                                key={index}
                                className={`text-llarge py-2 px-3 hover:bg-gray-95 cursor-pointer ${selectedItem?.includes(option.value) ? 'bg-[#DEE3E6]' : ''}`}
                                onClick={() => handleClick(option)}
                                role="option" // Indicate element is an option within a listbox
                                aria-selected={selectedItem === option.value} // Mark selected option
                            >
                                {option.value}
                            </span>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomSelects;