import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
import { AxiosError } from "axios";
import { ArithmeticalEvaluation, ArithmeticalEvaluationByYear, EvaluatedRight, IndicatorTypeEvaluation } from "../../../interfaces/Evaluations";
const { REACT_APP_API_PATH } = process.env;

interface GetEvaluationQuery {
    right: string;
    period?: string;
}

interface GetArithmeticalEvaluationQuery {
    right: string
    period?: string;
}

interface GetEvaluationResponse {
    evaluation_right: EvaluatedRight | null
    evaluations_indicator_type: IndicatorTypeEvaluation[],
}

interface GetArithmeticalEvaluationResponse {
    arithmetical_evaluation_right: ArithmeticalEvaluation,
    periodsToEvaluate: { id: number, value: string }[]
}

interface ErrorResponse {
    message: string;
    statusCode: number;
}

//Union de estructurales y procesos
export const getEvaluationRight = createAsyncThunk<GetEvaluationResponse, GetEvaluationQuery, { rejectValue: ErrorResponse }>(
    "evaluation/evaluation_right",
    async ({ right, period }: GetEvaluationQuery, { rejectWithValue }) => {
        try {
            let url1 = `${REACT_APP_API_PATH}/evaluacion/trivalente?derecho=${right}`;
            let url2 = `${REACT_APP_API_PATH}/estructurales?derechoId=${right}`;

            if (period) {
                url1 += `&periodo=${period}`;
                url2 += `&periodo=${period}`;
            }

            // Realiza ambas llamadas en paralelo
            const [response1, response2] = await Promise.all([
                axiosInstance.get(url1),
                axiosInstance.get(url2)
            ]);

            const data1 = response1.data;
            const data2 = response2.data;

            const evaluationsIndicatorType = [
                ...data1.derecho.tipos_indicadores.filter((type: any) => type.nombre !== "Estructurales"),
                ...data2.derecho.tipos_indicadores
            ];

            return {
                evaluation_right: data1.derecho,
                evaluations_indicator_type: evaluationsIndicatorType,
                error: false
            };
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                return rejectWithValue({
                    message: error.response.data?.message || 'Error desconocido',
                    statusCode: error.response.status,
                });
            }
            return rejectWithValue({
                message: 'Error de red o servidor',
                statusCode: 500,
            });
        }
    }
);

export const getArithmeticalEvaluation = createAsyncThunk<
    GetArithmeticalEvaluationResponse,
    GetArithmeticalEvaluationQuery,
    { rejectValue: ErrorResponse }
>(
    "evaluation/arithmetical_evaluation_right",
    async ({ right, period }: GetArithmeticalEvaluationQuery, { rejectWithValue }) => {
        try {
            let url = `${REACT_APP_API_PATH}/evaluacion/sumatoria?derecho=${right}`;

            const response = await axiosInstance.get(url);
            const data: ArithmeticalEvaluationByYear = response.data;

            // Validar que 'data' exista y tenga al menos una clave antes de proceder
            if (!data || Object.keys(data).length === 0) {
                return rejectWithValue({
                    message: 'No hay datos disponibles para el periodo solicitado.',
                    statusCode: 404,
                });
            }

            // Si hay un periodo especificado y no se encuentra en 'data'
            if (period && !(period in data)) {
                return rejectWithValue({
                    message: `El periodo ${period} no se encontró en los datos.`,
                    statusCode: 400,
                });
            }

            return {
                arithmetical_evaluation_right: data[period ?? ''], // Asegurarse de que period sea válido
                periodsToEvaluate: Object.keys(data).map((year, index) => ({
                    id: index + 1,
                    value: 'Período ' + parseInt(year).toString(),
                })),
                error: false,
            };
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                return rejectWithValue({
                    message: error.response.data?.message || 'Error desconocido',
                    statusCode: error.response.status,
                });
            }
            return rejectWithValue({
                message: 'Error de red o servidor',
                statusCode: 500,
            });
        }
    }
);
